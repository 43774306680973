import baseHttp from "@store/api.js";

const state = () => ({

});

const getters = {

};

const actions = {
    async process({}, params) {
        return await baseHttp.post(`bank-transfer/process`, params);
    },
};

const mutations = {

};

const bankTransferProcessing = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default bankTransferProcessing;


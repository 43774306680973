import { createStore } from 'vuex';
import auth from "@store/modules/auth/index";
import home from "@store/modules/home/index";
import sideBar from "@store/modules/sideBar/index";
import language from "@store/modules/language/index";
import currency from "@store/modules/currency/index";
import notification from "@store/modules/notification/index";
import tax from "@store/modules/tax/index";
import social from "@store/modules/social/index";
import dgd from "@store/modules/dgd/index";
import trustpilot from "@store/modules/trustpilot/index";
import mediaSetting from "@store/modules/mediaSetting/index";
import media from "@store/modules/media/index";
import attributeType from "@store/modules/attributeType/index";
import shippingZone from "@store/modules/shippingZone/index";
import loading from "@store/modules/loading/index";
import category from "@store/modules/category/index";
import user from "@store/modules/user/index";
import review from "@store/modules/review/index";
import userGroup from "@store/modules/userGroup/index";
import feed from "@store/modules/feed/index";
import attribute from "@store/modules/attribute/index";
import menu from "@store/modules/menu/index";
import product from "@store/modules/product/index";
import coupon from "@store/modules/coupon/index";
import order from "@store/modules/order/index";
import permission from "@store/modules/permission/index";
import translation from "@store/modules/translation/index";
import permalink from "@store/modules/permalink/index";
import upload from "@store/modules/upload/index";
import vcountry from "@store/modules/vcountry/index";
import page from "@store/modules/page/index";
import storeCountry from "@store/modules/storeCountry/index";
import vendor from "@store/modules/vendor/index";
import documentSettingsGeneral from "@store/modules/documentSettingsGeneral/index";
import generalSetting from "@store/modules/generalSetting/index";
import documentSetting from "@store/modules/documentSettingIndividual/index";
import emailSetting from "@store/modules/emailSetting/index";
import reminderEmail from "@store/modules/reminderEmail/index";
import calculator from "@store/modules/calculator/index";
import postCategory from "@store/modules/postCategory/index";
import aPlusContent from "@store/modules/aPlusContent/index";
import post from "@store/modules/post/index";
import accountingSetting from "@store/modules/accountingSetting/index";
import accountingFile from "@store/modules/accountingFile/index";
import shippingLabelSetting from "@store/modules/shippingLabelSetting/index";
import item from "@store/modules/item/index";
import zipRule from "@store/modules/zipRule/index";
import bankTransferProcessing from "@store/modules/bankTransferProcessing/index";

const store = createStore({
    modules: {
        auth,
        sideBar,
        language,
        currency,
        notification,
        loading,
        tax,
        home,
        shippingZone,
        shippingLabelSetting,
        social,
        dgd,
        trustpilot,
        mediaSetting,
        media,
        attributeType,
        category,
        user,
        review,
        userGroup,
        feed,
        menu,
        product,
        coupon,
        attribute,
        permission,
        translation,
        permalink,
        documentSettingsGeneral,
        generalSetting,
        documentSetting,
        emailSetting,
        reminderEmail,
        accountingSetting,
        accountingFile,
        upload,
        aPlusContent,
        vcountry,
        page,
        storeCountry,
        postCategory,
        calculator,
        vendor,
        post,
        item,
        order,
        zipRule,
        bankTransferProcessing,
    }
});
export default store;
